<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户名" prop="mobile" >
        <a-input :disabled=true v-model="form.mobile" />
      </a-form-model-item>
<!--      <a-form-model-item label="密码，md5" prop="passwd" >
        <a-input v-model="form.passwd" placeholder="请输入密码，md5" />
      </a-form-model-item>
      <a-form-model-item label="支付密码, md5" prop="payPasswd" >
        <a-input v-model="form.payPasswd" placeholder="请输入支付密码, md5" />
      </a-form-model-item>-->
      <a-form-model-item label="账户余额" prop="money" >
        <a-input :disabled=true v-model="form.money"/>
      </a-form-model-item>
<!--      <a-form-model-item label="支付宝账号" prop="aliPay" >
        <a-input :disabled=true v-model="form.aliPay" />
      </a-form-model-item>-->
<!--      <a-form-model-item label="微信openid" prop="openid" >
        <a-input :disabled=true v-model="form.openid" />
      </a-form-model-item>-->
      <a-form-model-item v-if="form.realName" label="真实姓名" prop="realName" >
        <a-input :disabled=true v-model="form.realName"/>
      </a-form-model-item>
      <a-form-model-item v-if="form.idCardPortrait" label="身份证人像面" prop="avatar" >
        <div v-viewer>
          <img v-if="form.idCardPortrait" width="100" height="100" :src="form.idCardPortrait">
        </div>
      </a-form-model-item>
      <a-form-model-item v-if="form.idCardEmblem" label="身份证国徽面" prop="avatar" >
        <div v-viewer>
          <img v-if="form.idCardEmblem" width="100" height="100" :src="form.idCardEmblem">
        </div>
      </a-form-model-item>
      <a-form-model-item label="头像" prop="avatar" >
        <div v-viewer>
          <img v-if="form.avatar" width="100" height="100" :src="form.avatar">
        </div>
      </a-form-model-item>
      <a-form-model-item label="昵称" prop="nickname" >
        <a-input :disabled=true v-model="form.nickname" placeholder="请输入昵称" />
      </a-form-model-item>
      <a-form-model-item label="性别" prop="sex" >
        <a-select :disabled=true v-model="form.sex">
          <a-select-option :value="0">保密</a-select-option>
          <a-select-option :value="1">男</a-select-option>
          <a-select-option :value="2">女</a-select-option>
        </a-select>

      </a-form-model-item>
<!--      <a-form-model-item label="生日，格式1990-04-22" prop="birthday" >
      </a-form-model-item>-->
<!--      <a-form-model-item label="个性签名" prop="userSign" >
        <a-input v-model="form.userSign" placeholder="请输入个性签名" />
      </a-form-model-item>-->
<!--      <a-form-model-item label="是否推送 1-是 0-否" prop="isNotice" >
        <a-input v-model="form.isNotice" placeholder="请输入是否推送 1-是 0-否" />
      </a-form-model-item>-->
<!--      <a-form-model-item label="签到天数" prop="signDay" >
        <a-input v-model="form.signDay" placeholder="请输入签到天数" />
      </a-form-model-item>-->
      <a-form-model-item label="用户状态" prop="status" >
        <a-radio-group v-model="form.status">
          <a-radio :value="0">禁用</a-radio>
          <a-radio :value="1">正常</a-radio>
        </a-radio-group>
      </a-form-model-item>
<!--      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUser, addUser, updateUser } from '@/api/user/user'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        mobile: null,

        passwd: null,

        payPasswd: null,

        money: null,

        aliPay: null,

        openid: null,

        realName: null,

        idCardPortrait: null,

        idCardEmblem: null,

        avatar: null,

        nickname: null,

        sex: null,

        birthday: null,

        userSign: null,

        isNotice: null,

        signDay: null,

        status: 0,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        mobile: [
          { required: true, message: '用户名/手机号，用于登录不能为空', trigger: 'blur' }
        ],
        money: [
          { required: true, message: '账户余额不能为空', trigger: 'blur' }
        ],
        nickname: [
          { required: true, message: '昵称不能为空', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '性别,0-保密 1-男 2-女不能为空', trigger: 'change' }
        ],
        isNotice: [
          { required: true, message: '是否推送 1-是 0-否不能为空', trigger: 'blur' }
        ],
        signDay: [
          { required: true, message: '签到天数不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '用户状态，1-正常，0禁用，禁用操作请注意清除token，同时不允许用户登录不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        mobile: null,
        passwd: null,
        payPasswd: null,
        money: null,
        aliPay: null,
        openid: null,
        realName: null,
        idCardPortrait: null,
        idCardEmblem: null,
        avatar: null,
        nickname: null,
        sex: null,
        birthday: null,
        userSign: null,
        isNotice: null,
        signDay: null,
        status: 0,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUser({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUser(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
